import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Customer } from '@fc-core';

@Component({
  selector: 'fc-payment-alert',
  template: `
    <fc-alerts
      *ngIf="!hidden"
      (closeEmitter)="hidden = true"
      [closeButton]="true"
      type="error"
    >
      ⚠️ Your account is past due.
      <a target="_blank" [href]="customer?.paymentMethodUrl"
        >Update your payment</a
      >
      within 30 days of a failed payment or your service will be suspended. If
      you have any questions
      <a target="_blank" href="mailto:support@fleetchaser.com">contact us.</a>
    </fc-alerts>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      @use 'var' as *;

      :host {
        position: fixed;
        z-index: 1001;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);

        @media print {
          display: none;
        }

        a {
          color: $primary-500;
        }

        @media (max-width: $sm-size) {
          left: 10px;
          right: 10px;
          transform: none;
        }
      }
    `,
  ],
})
export class PaymentAlertComponent {
  @Input() customer: Customer;
  hidden = false;
}
